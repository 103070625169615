<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Pembatalan Verifikasi</h4>
          </template>
          <template v-slot:body>
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-25"
                    id="filter-input"
                    v-model="filterTable"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="thead"
                :items="listData"
                class="mt-2"
                :busy="isLoadingTable"
                :filter="filterTable"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
                show-empty
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div
                    class="
                      bg-light
                      text-danger
                      font-weight-bold
                      mx-auto
                      text-center
                    "
                  >
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
import { mapState } from 'vuex'
import { Vue } from 'vue-property-decorator'
import store from '@/store/index'
import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'menuKhusus.pembatalanVerifikasi',
  computed: {
    ...mapState('authService', ['token'])
  },
  async mounted() {
    this.innitData()
  },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    /**
     * Innit Data
     */
    async innitData() {
      // innit data code...
    }
  },
  data() {
    return {
      // Tabel Options
      isLoadingTable: false,
      filterTable: null,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPage: 1,
      totalRows: 1,
      // List Data
      listData: [],
      // Table
      thead: [
        {
          label: 'Kode KS',
          key: 'kodePks',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Kode Jenis KS',
          key: 'kodeJenisPks',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Keterangan',
          key: 'keterangan',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Nomor KS UT',
          key: 'nomorKsUt',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Nomor KS Mitra',
          key: 'nomorKSMitra',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Kode Mitra',
          key: 'kodeMitra',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Kontak Mitra',
          key: 'kontakMitra',
          class: 'text-center',
          sortable: true
        },
        { label: 'Aksi', key: 'aksi', class: 'text-center w-25' }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    const idUnitKs = store.state.authService.idUnitKs
    if (
      MENU_ROLE.MENU_KHUSUS_PEMBATALAN_VERIFIKASI.includes(String(idUnitKs))
    ) {
      return next()
    } else {
      Vue.$toast.error('Anda tidak memiliki hak akses')
      return next({ name: 'sidebar.dashboard' })
    }
  }
}
</script>
